.chatbot-widget {
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 350px;
  max-height: 650px;
  background-color: #f8f9fa;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  transition: transform 0.3s ease-in-out, visibility 0.3s;
  transform: translateY(150%);
  visibility: hidden;
}

.chatbot-widget.open {
  transform: translateY(0);
  visibility: visible;
}

.chat-window {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
}

.message-wrapper {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message-wrapper.user {
  align-items: flex-end;
}

.message-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.message-info .name {
  font-weight: bold;
  margin-right: 5px;
  font-size: 14px;
}

.avatar {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin-right: 10px;
}

.name {
  font-weight: bold;
  margin-right: 5px;
}

.timestamp {
  font-size: 10px;
  color: #999;
}

.message {
  padding: 10px;
  border-radius: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  display: inline-block;
  max-width: 75%;
}

.message.user {
  background-color: #0258FD;
  color: #fff;
  text-align: right;
  margin-left: auto;
}

.message.bot {
  background-color: #e2e3e5;
  color: #333;
}

.message-content {
  display: flex;
  flex-direction: column;
}

.message-body {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  justify-content: center;
  margin-bottom: 10px;
}

.options button {
  padding: 10px 15px;
  background-color: #0d6efd;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  flex: 0 1 auto;
}

.options button:hover {
  background-color: #15245C;
}

.chat-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 5px;
  margin-top: -20px;
}

.chat-input-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  outline: none;
}

.chat-input-container button {
  background-color: #0258FD;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chat-input-container button:hover {
  background-color: #15245C;
}

.chat-input-container button svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}

input.custom-input {
  margin-top: 10px;
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

header {
  background-color: #0258FD;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.header-title {
  font-size: 18px;
  margin-right: -50px;
}


.header-subtitle {
  font-size: 12px;
  color: #dcdcdc;
}

.company-logo {
  width: 150px;
  height: auto;
}

.chat-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0258FD;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: transform 0.3s ease;
}

.chat-toggle:hover {
  transform: scale(1.1);
}

.submit-button {
  background-color: #0258FD;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 5px;
  margin-bottom: -10px;
}

.submit-button:hover {
  background-color: #15245C;
}

.submit-button svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.refresh-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
}

.feedback-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-top: 10px;
}

.feedback-logo{
  width: 25px;
  height: 25px;
}

.refresh-logo {
  width: 23px;
  height: 23px;
}

.refresh-icon:hover .refresh-logo,
.feedback-icon:hover .feedback-logo {
  filter: brightness(0.8);
}

.react-select__control {
  margin-top: 10px;
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  outline: none;
}

.react-select__control--is-focused {
  border-color: #0258FD;
  box-shadow: 0 0 0 3px rgba(2, 88, 253, 0.3);
}

.react-select__menu {
  width: calc(100% - 20px);
}

.react-select__option {
  display: flex;
  align-items: center;
}

.react-select__option img {
  margin-right: 10px;
}

details {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
}

summary {
  font-weight: bold;
  cursor: pointer;
}

details[open] summary {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.thinking-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.feedback-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.feedback-form-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px; 
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: Arial, sans-serif; 
}

.feedback-textarea, .feedback-email-input {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
}

.submit-feedback-button, .cancel-feedback-button {
  padding: 10px 20px;
  margin: 5px 0;
  border: none;
  border-radius: 20px; 
  cursor: pointer;
  transition: background-color 0.3s; 
  font-size: 14px;
}

.submit-feedback-button {
  background-color: #0258FD;
  color: white;
}

.submit-feedback-button:hover {
  background-color: #15245C; 
}

.cancel-feedback-button {
  background-color: #6c757d;
  color: white;
}

.cancel-feedback-button:hover {
  background-color: #525e64;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-text {
  margin-left: 10px;
  flex-grow: 1;
}

.header-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  justify-content: center;
  margin-left: 10px;
}

.feedback-text {
  font-size: 13px;
  font-weight: bold;
}

.feedback-form-container h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
  line-height: 1.4;
}

.feedback-email-input:focus, .feedback-textarea:focus {
  border-color: #0258FD;
  box-shadow: 0 0 0 3px rgba(2, 88, 253, 0.3);
}

.feedback-textarea::placeholder,
.feedback-email-input::placeholder {
  font-family: Arial, sans-serif; 
  font-size: 14px;
  color: #6c757d;
}

.feedback-textarea, .feedback-email-input {
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.intro-message {
  position: fixed;
  bottom: 80px;
  right: 30px;
  z-index: 9999;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 330px;
  background: transparent;
}

.intro-content {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  position: relative;
}

.intro-content img {
  width: 40px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.intro-options {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  margin-top: 10px;
  width: 100%;
}

.intro-options button {
  background-color: #0d6efd;
  border: none;
  color: #fff;
  border-radius: 20px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  text-align: left; 
  white-space: nowrap; 
}

.intro-options button:hover {
  background-color: #0258FD;
  color: white;
  border-color: #0258FD;
}

.intro-options button:hover {
  background-color: #15245C;
}

.intro-content .close-button {
  display: none;
  position: absolute;
  z-index: 10;
  background: #e0e0e0;
  border: none;
  font-size: 12px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.intro-content .close-button {
  top: -10px;
  right: 10px;
  font-size: 16px;
  color: #333;
}

.intro-message:hover .close-button {
  display: flex;
}

.close-button:hover {
  background-color: #d1d1d1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.close-button:hover {
  color: red;
}

@media (max-width: 600px) {
  .chatbot-widget {
    max-height: 78vh; /* Fix the height to 78% of the viewport height */
    width: 100%; /* Make the widget full width */
    right: 0; /* Position it at the right */
    border-radius: 0; /* Remove border radius */
  }

  .chat-window {
    max-height: calc(78vh - 100px); /* Adjust the height of the chat window */
  }

  .intro-content .close-button {
    display: flex; /* Always display the close button on mobile */
  }
}
